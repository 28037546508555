import self from "../img/Cattura.PNG"


export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export const 
info = {
    firstName: "Klejdi",
    lastName: "Kristo",
    initials: "KK", 
    position: "a Full Stack Developer",
    selfPortrait: self, 
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, 
    baseColor: colors[0],
    miniBio: [ 
        {
            emoji: '🍫',
            text: 'fueled by chocolate'
        },
        {
            emoji: '🌎',
            text: 'based in Italy'
        },
        {
            emoji: "💼",
            text: "Full Stack Developer @ Aruba S.P.A"
        },
        {
            emoji: "📧",
            text: "klejdikristo@gmail.com"
        }
    ],
    socials: [
        {
            link: "https://github.com/klejdi94",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/klejdi-kristo/",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://twitter.com/klejdikristo",
            icon: "fa fa-twitter",
            label: 'twitter'
        }
    ],
    bio: "Hello! I'm Klejdi. A passionate Full Stack Developer having an experience of building Web applications with Angular / Spring Boot / Javascript / MongoDB  and some other cool libraries and frameworks.",
    skills:
        {
            proficientWith: ['javascript', 'react', 'git', 'angular', 'java','spring boot / cloud / webflux','typescript','keycloak','Docker','Kubernetes'],
            exposedTo: []
        }
    ,
    hobbies: [
        {
            label: 'reading',
            emoji: '📖'
        },
        {
            label: 'football',
            emoji: '⚽'
        },
        {
            label: 'gaming',
            emoji: '🎮'
        },
        {
            label: 'astronomy',
            emoji: '🔭'
        },
        {
            label: 'coding',
            emoji: '🖥️'
        },
    ],
    portfolio: [ 
    ]
}